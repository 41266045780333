/*****************************************************************************
 * UmanIT - Bloc Univers Gourmand (homepage)
 *****************************************************************************/

const mainImages = document.querySelectorAll(".gourmand-universe__img");
const descriptions = document.querySelectorAll(".gourmand-universe__desc");
const thumbnails = document.querySelectorAll(".gourmand-universe__thumbnail");

thumbnails.forEach(thumbnail => thumbnail.addEventListener("click", function(e) {
  e.preventDefault();

  const range = thumbnail.dataset.content;
  const mainImage = document.querySelector(`.gourmand-universe__img[data-content='${range}']`);
  const description = document.querySelector(`.gourmand-universe__desc[data-content='${range}']`);

  removeActive(mainImages);
  removeActive(descriptions);
  removeActive(thumbnails);

  addActive(thumbnail, description, mainImage);
}));

function removeActive(items) {
  items.forEach(item => item.classList.remove("active"));
}

function addActive(thumbnail, description, mainImage) {
  thumbnail.classList.add("active");
  mainImage.classList.add("active");
  description.classList.add("active");
}
